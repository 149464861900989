import {Controller} from "@hotwired/stimulus"
import $ from "jquery";

// Connects to data-controller="category-menu"
export default class extends Controller {
    connect() {
        this.hide_timeout = null
        this.start_timer = () => {
            console.log('start timer');
            this.hide_timeout = setTimeout((ev) => {this.hide(ev)}, 500);
        }

    }

    toggle(event) {
        const menu = $('#category-menu');
        const that = $(event.target);
        const vp_width = $(window).width();
        let offs = that.offset();
        menu.toggleClass('init');
        offs.top = 112;
        if (offs.left + 250 > vp_width) {
            offs.left = vp_width - 250;
        }
        menu.offset(offs);
    }

    show(event) {
        this.quit_timout()
        const menu = $('#category-menu');
        const that = $(event.target);
        const vp_width = $(window).width();
        let offs = that.offset();
        menu.removeClass('init');
        offs.top = 112;
        if (offs.left + 250 > vp_width) {
            offs.left = vp_width - 250;
        }
        menu.offset(offs);
        // menu.on('mouseenter', this.quit_timout)
        // menu.on('mouseout', this.start_timer.bind(this))
    }
    delayHide(event) {
        if (event.target !== event.currentTarget) return;

        console.log(event.target);
        console.log('delayHide');
        this.start_timer()
    }

    stopHide(event) {
        if (event.target !== event.currentTarget) return;

        console.log(event.target);
        this.quit_timout()
    }

    hide(event) {
        const menu = $('#category-menu');
        if (menu.is(':hover')) return;

        menu.addClass('init');
    }


    quit_timout() {
        console.log('quit_timout');
        if (this.hide_timeout) {
            console.log('quit_timout!');
            clearTimeout(this.hide_timeout)
            this.hide_timeout = null;
        }
    }
}
